$(function() {

  var answ_yes, close_overlay, show_overlay;

  answ_yes = function(data) {
    var category_id, event_id;
    event_id = $('#event-wrapper').attr('event-id');
    category_id = $('#heat-options').attr('category-id');
    $('.info-overlay .info').hide();
    $('.info-overlay .spinner').show();
    return $.ajax({
      url: "/categories/" + category_id,
      type: "put",
      data: {
        category: data
      },
      success: function() {
        // return $.ajax({
        //   url: "/toolboxes/" + event_id,
        //   success: function() {
        //     return $.ajax({
        //       url: "/heats",
        //       format: 'js',
        //       data: {
        //         event_id: event_id
        //       },
        //       success: function() {
        //         $(".info-overlay").fadeOut(700);
        //         return setTimeout(function() {
        //           $('.info-overlay .info').show();
        //           return $('.info-overlay .spinner').hide();
        //         }, 1000);
        //       }
        //     });
        //   }
        // });
      }
    });
  };

  show_overlay = function(h2) {
    if (h2 == null) {
      h2 = false;
    }
    $(".info-overlay > div").hide();
    if (h2) {
      $('.info-overlay .info h2').html(h2);
    }
    $(".info-overlay .info").show();
    return $(".info-overlay").fadeIn(500);
  };

  close_overlay = function() {
    $(".info-overlay").fadeOut(500);
    return $.ajax({
      url: "/toolboxes/" + $('#event-wrapper').attr('event-id')
    });
  };
  $(document).on('click', '#heat-options li', function() {
    console.log($('input#lcq_survivors').val());
    return setTimeout(function() {
      var data;
      show_overlay();
      data = {
        heatsize: $('#heatsize li.active div').html(),
        lcq_size: $('#lcq_size li.active div').html(),
        lcq_survivors: $('input#lcq_survivors').val()
      };
      $(".answers .no").on('click', function() {
        return close_overlay();
      });
      return $(".answers .yes").on('click', function() {
        return answ_yes(data);
      });
    }, 100);
  });

  $(document).on('keyup', '#lcq_survivors', function(e) {
    var survivors, event_id;
    if (e.keyCode === 13) {
      suvivors = $("#lcq_survivors").first().val();
      category_id = $('#heat-options').attr('category-id');
      return $.ajax({
        url: "/categories/" + category_id,
        type: "put",
        data: {
          category: {
            'lcq_survivors': suvivors
          },
          render_plain: true
        },
        success: function() {
          return console.log("DONE");
        }
      });
    }
  });

});




// 
// var answ_yes, change_heat_view, change_heatround, change_heatsize, close_overlay, flash_error, flash_success, highlight_rider, placement_tablet, post_update_list_view, post_update_tablet, reset_buttons, send_delete_placement, send_placement_update, set_buttons_available, set_placement, set_selected, show_overlay;
//
// flash_success = function(title, message) {
//   $("#flash-title").html(title);
//   $("#flash-content").html(message);
//   $('#flash').css('background-color', 'rgba(117, 224, 109, 0.6)');
//   $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
//   return $("#flash").fadeIn(500).delay(3500).fadeOut(500);
// };
//
// flash_error = function(title, message) {
//   $("#flash-title").html(title);
//   $("#flash-content").html(message);
//   $('#flash').css('background-color', 'rgba(252, 93, 90, 0.6)');
//   $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
//   return $("#flash").fadeIn(200).delay(3500).fadeOut(200);
// };
//
// answ_yes = function(data) {
//   var event_id;
//   event_id = $('#event-wrapper').attr('event-id');
//   $('.info-overlay .info').hide();
//   $('.info-overlay .spinner').show();
//   return $.ajax({
//     url: "/events/" + event_id,
//     type: "put",
//     data: {
//       event: data
//     },
//     success: function() {
//       return $.ajax({
//         url: "/toolboxes/" + event_id,
//         success: function() {
//           return $.ajax({
//             url: "/heats",
//             format: 'js',
//             data: {
//               event_id: event_id
//             },
//             success: function() {
//               $(".info-overlay").fadeOut(700);
//               return setTimeout(function() {
//                 $('.info-overlay .info').show();
//                 return $('.info-overlay .spinner').hide();
//               }, 1000);
//             }
//           });
//         }
//       });
//     }
//   });
// };
//
// show_overlay = function(h2) {
//   if (h2 == null) {
//     h2 = false;
//   }
//   $(".info-overlay > div").hide();
//   if (h2) {
//     $('.info-overlay .info h2').html(h2);
//   }
//   $(".info-overlay .info").show();
//   return $(".info-overlay").fadeIn(500);
// };
//
// close_overlay = function() {
//   $(".info-overlay").fadeOut(500);
//   return $.ajax({
//     url: "/toolboxes/" + $('#event-wrapper').attr('event-id')
//   });
// };
//
// change_heatsize = function(size, cat) {};
//
// $(function() {
//   var dns, luckyLooser;
//   if (($("#live").length)) {
//     setInterval((function() {
//       console.log("get current heat");
//       $.ajax({
//         url: "/events/" + $('#live').attr('event-id') + "/get_current_heat",
//         success: function(heat_id) {
//           console.log("current heat :", heat_id);
//           if (heat_id !== $('#live').attr('heat-id')) {
//             return location.href = "/events/" + $('#live').attr('event-id') + "/current_heat?user_type=livescreen";
//           }
//         }
//       });
//     }), 3000);
//   }
//   $(document).ready(function() {
//     return $('.live-btn').each(function(i, btn) {
//       var $btn;
//       $btn = $(btn);
//       if ($btn.attr('type') === $('#livescreen-control').attr('mode')) {
//         return $btn.addClass("active");
//       }
//     });
//   });
//   $(document).on('click', '.live-btn', function() {
//     var id, type;
//     type = $(this).attr('type');
//     $('.live-btn').removeClass('active');
//     $(this).addClass("active");
//     if (type) {
//       return $.ajax({
//         url: "/livescreen_mode",
//         type: "post",
//         data: {
//           mode: type
//         },
//         success: function(res) {
//           return console.log("res:" + res);
//         }
//       });
//     } else if ($(this).hasClass('goto')) {
//       id = $('#go-to').val();
//       if (id > 0) {
//         return $.ajax({
//           url: "/set_livescreen",
//           type: "post",
//           data: {
//             heat_id: id
//           },
//           success: function(res) {
//             console.log("RES?");
//             return location.href = "/heats/" + id + "?user_type=livescreen_control";
//           }
//         });
//       }
//     } else {
//       id = $(this).attr('heat-id');
//       return $.ajax({
//         url: "/set_livescreen",
//         type: "post",
//         data: {
//           heat_id: id
//         },
//         success: function(res) {
//           return location.href = "/heats/" + id + "?user_type=livescreen_control";
//         }
//       });
//     }
//   });
//   $(document).on('click', '#survivors-to-main', function() {
//     var category_id, event_id;
//     category_id = $('#heat-options').attr('category-id');
//     event_id = $('#event-wrapper').attr('event-id');
//     return $.ajax({
//       url: "/events/" + event_id + "/lcq_survivors_to_final_heats",
//       data: {
//         category: category_id
//       },
//       success: function() {
//         return location.reload();
//       }
//     });
//   });
//   $(document).on('click', '#random-heat-results', function() {
//     var category_id, lcq;
//     category_id = $('#heat-options').attr('category-id');
//     lcq = $('.box-chooser.lcq .active').hasClass('lcq');
//     console.log(lcq);
//     return $.ajax({
//       url: "/categories/" + category_id + "/random_heat_results",
//       data: {
//         lcq: lcq
//       },
//       success: function() {
//         return location.reload();
//       }
//     });
//   });
//   $(document).on('click', '.start-btn', function() {
//     var id;
//     id = $(this).attr('heat-id');
//     return $.ajax({
//       url: "/set_livescreen",
//       type: "post",
//       data: {
//         heat_id: id
//       },
//       success: function(res) {
//         return location.href = "/heats/" + id + "?user_type=start";
//       }
//     });
//   });
//   if (($('#live').length)) {
//     $.ajax({
//       url: "/set_livescreen",
//       type: "post",
//       data: {
//         heat_id: $('#live').attr('heat-id')
//       }
//     });
//     setInterval((function() {
//       $.ajax({
//         url: "/get_livescreen",
//         success: function(heat_id) {
//           console.log(heat_id);
//           if (heat_id !== $('#live').attr('heat-id')) {
//             return location.href = "/heats/" + heat_id + "?user_type=livescreen";
//           }
//         }
//       });
//       $.ajax({
//         url: "/livescreen_mode",
//         success: function(mode) {
//           if (mode !== $('#live').attr('mode')) {
//             return location.href = "/heats/" + $('#live').attr('heat-id') + "?user_type=livescreen";
//           }
//         }
//       });
//       $.ajax({
//         url: "/heats/" + $('#live').attr('heat-id') + "/get_heatriders",
//         success: function(res) {
//           return $.each(res, function(index, heatrider) {
//             var $placement;
//             if (heatrider.finished > 0) {
//               $placement = $('div[heatrider-id=' + heatrider.id + '] .placement-overlay');
//               $placement.html(heatrider.placement);
//               return $placement.fadeIn(400);
//             }
//           });
//         }
//       });
//     }), 3000);
//     setInterval((function() {
//       console.log("get current heat");
//       $.ajax({
//         url: "/events/" + $('#live').attr('event-id') + "/get_current_heat",
//         success: function(heat_id) {
//           console.log("current heat :", heat_id);
//           if (heat_id !== $('#live').attr('heat-id')) {
//             return location.href = "/events/" + $('#live').attr('event-id') + "/current_heat?user_type=livescreen";
//           }
//         }
//       });
//     }), 3000);
//   }
//   $(document).on('click', '.box-chooser.heatround li', function() {
//     return change_heatround($(this));
//   });
//   $(document).on('click', '.heat-btn', function() {
//     return set_placement($(this));
//   });
//   $(document).on('click', '.delete-heat', function() {
//     var id;
//     show_overlay("REALLY?");
//     id = $(this).attr('heat-id');
//     $(".answers .no").on('click', function() {
//       return close_overlay();
//     });
//     return $(".answers .yes").on('click', function() {
//       close_overlay();
//       return $.ajax({
//         url: "/heats/" + id,
//         type: "delete",
//         success: function(res) {
//           return console.log("DONE");
//         }
//       });
//     });
//   });
//   $(document).on('click', '.delete-heatriders', function() {
//     var id;
//     show_overlay();
//     id = $(this).attr('heat-id');
//     $(".answers .no").on('click', function() {
//       return close_overlay();
//     });
//     return $(".answers .yes").on('click', function() {
//       close_overlay();
//       return $.ajax({
//         url: "/heats/" + id + "/delete_heatriders",
//         type: "delete",
//         success: function(res) {
//           return console.log("DONE");
//         }
//       });
//     });
//   });
//   $(document).on('click', '.rider-img-wrapper', function() {
//     var $rider_wrapper;
//     $rider_wrapper = $(this);
//     highlight_rider($rider_wrapper);
//     if ($('.outside-frame').hasClass('finish-view')) {
//       set_buttons_available($rider_wrapper);
//     } else {
//       console.log("do start");
//       $rider_wrapper.parent().parent().parent().find('.rider-btn').toggle();
//     }
//     return reset_buttons($(this));
//   });
//   $(document).on('click', '.next-heat', function() {
//     return change_heat_view('#review-heat');
//   });
//   $(document).on('click', '.previous-heat', function() {
//     return change_heat_view('#review-heat', -1);
//   });
//   $(document).on('click', '.next-heat-btn', function() {
//     return location.href = "/heats/" + $('#heat').attr('next-heat-id') + "?user_type=finish";
//   });
//   $(document).on('click', '.unlock', function() {
//     $('.heat-frame').removeClass('show');
//     $('.unlock').removeClass('active');
//     $(this).parent().toggleClass('show');
//     return $(this).toggleClass('active');
//   });
//   luckyLooser = function($frame) {
//     var $looser;
//     $looser = $frame.find('.lucky-looser');
//     if (($frame.find('.rider-img-wrapper.active .looser:visible').length)) {
//       return $looser.addClass('active');
//     } else {
//       return $looser.removeClass('active');
//     }
//   };
//   dns = function($frame) {
//     var $looser;
//     $looser = $frame.find('.dns');
//     if (($frame.find('.rider-img-wrapper.active .dns-overlay:visible').length)) {
//       return $looser.addClass('active');
//     } else {
//       return $looser.removeClass('active');
//     }
//   };
//   $(document).on('click', '.box-chooser.lcq li', function() {
//     var before;
//     before = Cookies.get('lcq');
//     if ($('.box-chooser.lcq li.active').hasClass('lcq')) {
//       Cookies.set('lcq', true);
//     } else {
//       Cookies.set('lcq', false);
//     }
//     if (before !== Cookies.get('lcq')) {
//       $('.heats').toggleClass('hide');
//       return $('ul.heatround').toggleClass('hide');
//     }
//   });
//   $(document).on('click', '.dns', function() {
//     var $this, heatrider_id;
//     $this = $(this);
//     heatrider_id = $('.rider-img-wrapper.active').attr('heatrider-id');
//     return $.ajax({
//       url: "/heatriders/" + heatrider_id,
//       type: "PUT",
//       data: {
//         heatrider: {
//           finished: 0
//         }
//       },
//       success: function(data) {
//         var $parent;
//         console.log('here');
//         $parent = $this.parent();
//         $('.lucky-looser').removeClass('active');
//         $this.toggleClass('active');
//         $parent.find('.rider-img-wrapper.active .looser').hide();
//         return $parent.find('.rider-img-wrapper.active .dns-overlay').toggle();
//       }
//     });
//   });
//   return $(document).on('click', '.lucky-looser', function() {
//     var $parent;
//     $parent = $(this).parent();
//     $parent.find('.dns').removeClass('active');
//     $parent.find('.next-looser').toggle();
//     $(this).toggleClass('active');
//     $parent.find('.rider-img-wrapper.active .dns-overlay').hide();
//     return $parent.find('.rider-img-wrapper.active .looser').toggle();
//   });
// });
//
// change_heatround = function($chooser) {
//   var split;
//   $('.info-overlay > div').hide();
//   $('.info-overlay .spinner').show();
//   $('.info-overlay .spinner-text').show();
//   $('.info-overlay').fadeIn(200);
//   if ($('#split-heats')) {
//     split = $chooser.parent().attr('first');
//   } else {
//     split = -1;
//   }
//   return $.ajax({
//     url: "/heats",
//     type: "get",
//     format: 'js',
//     data: {
//       round: $chooser.attr('round'),
//       split: split,
//       event_id: $('#event-wrapper').attr('event-id'),
//       type: 'list_view'
//     },
//     success: function(data) {
//       $('.info-overlay').fadeOut(200);
//       return flash_success("Heatround loaded", "Heats have been updated");
//     },
//     error: function(data) {
//       $('.info-overlay').fadeOut(200);
//       return flash_error("Something went wrong", "Heats could not be loaded");
//     }
//   });
// };
//
// change_heat_view = function(view, offset) {
//   var heat_id;
//   if (offset == null) {
//     offset = 1;
//   }
//   heat_id = parseInt($(view).find('.heat-info').attr('heat-id')) + offset;
//   view += ' .heat-frame';
//   return $.ajax({
//     url: "/heats/" + heat_id,
//     format: 'js',
//     data: {
//       element_id: view
//     }
//   });
// };
//
// reset_buttons = function($rider_wrapper) {
//   if (!$rider_wrapper.hasClass('active')) {
//     $('.heat-btn').removeClass('active');
//     return $('.heat-btn').removeClass('available');
//   }
// };
//
// set_buttons_available = function($rider) {
//   var $pane;
//   $pane = $rider.parent().parent().parent().parent();
//   return $pane.find('.heat-btn').addClass('available');
// };
//
// set_selected = function($button) {
//   return $button.addClass("selected");
// };
//
// highlight_rider = function($rider) {
//   var $frame, $overlay;
//   $rider.toggleClass('active');
//   $frame = $rider.parent().parent().parent();
//   $overlay = $frame.find('.edit-overlay');
//   $overlay.css('background-color', $rider.parent().find('.rider-color').css('background-color'));
//   return $overlay.toggle();
// };
//
// set_placement = function($button) {
//   var $pane, $rider, $selected;
//   $pane = $button.parent().parent();
//   $rider = $pane.find('.rider-img-wrapper.active');
//   $selected = $pane.parent().find('.heatriders-in-heat li.selected');
//   if ($rider.length) {
//     placement_tablet($rider, $pane, $button);
//   }
//   if ($selected.length) {
//     return send_placement_update($button, $selected.attr('heatrider-id'));
//   }
// };
//
// placement_tablet = function($rider, $pane, $button) {
//   var $clone, old_val;
//   old_val = $rider.find('.placement-overlay').attr('placement');
//   $pane.find('.heat-btn[val=' + old_val + ']').removeClass('selected');
//   $('.heat-btn').removeClass('active');
//   $clone = $pane.find('.placement-overlay[placement=' + $button.attr('val') + ']');
//   if ($clone.length && $button.attr('val') > 0) {
//     send_delete_placement($clone);
//   }
//   send_placement_update($button, $rider.attr('heatrider-id'));
//   return set_selected($button);
// };
//
// post_update_list_view = function(data) {
//   var $item, $list, $options, heatrider, j, len, ref, results;
//   $list = $('ul[heat-id=' + data.heat_id + ']');
//   $options = $list.parent().find('.heat-options');
//   $options.removeClass('available');
//   $options.find('.heat-btn').removeClass('used');
//   ref = data.heatriders;
//   results = [];
//   for (j = 0, len = ref.length; j < len; j++) {
//     heatrider = ref[j];
//     if (heatrider.placement) {
//       $item = $('li[heatrider-id=' + heatrider.id + ']');
//       $item.attr('placement', heatrider.placement).removeClass('selected');
//       $item.find('.heatrider-placement').html(heatrider.placement).show();
//       results.push($options.find('.heat-btn[val=' + heatrider.finished + ']').addClass('used'));
//     } else {
//       results.push(void 0);
//     }
//   }
//   return results;
// };
//
// post_update_tablet = function(data, $button) {
//   var $overlay, $pane, placement;
//   placement = data.placement;
//   $overlay = $('div[heatrider-id=' + data.id + ']').find('.placement-overlay');
//   console.log('over', $overlay);
//   if (placement) {
//     $overlay.html(placement).show();
//     $overlay.attr('placement', data.finished);
//   } else {
//     $overlay.hide();
//   }
//   $button.toggleClass('active');
//   $pane = $button.parent().parent();
//   if ($pane.find('.placement-overlay:visible').length === 4) {
//     return $pane.find('.next-heat-btn').addClass('available');
//   } else {
//     return $pane.find('.next-heat-btn').removeClass('available');
//   }
// };
//
// send_delete_placement = function($clone) {
//   var heatrider_id;
//   heatrider_id = $clone.parent().attr('heatrider-id');
//   return $.ajax({
//     url: "/heatriders/" + heatrider_id + "/delete_placement",
//     type: "GET",
//     success: function(data) {
//       if (data) {
//         $clone.removeAttr('placement');
//         return $clone.hide();
//       } else {
//         return alert("something went wrong: delete_placement");
//       }
//     }
//   });
// };
//
// send_placement_update = function($button, heatrider_id) {
//   var finished;
//   finished = $button.attr('val');
//   return $.ajax({
//     url: "/heatriders/" + heatrider_id,
//     type: "PUT",
//     data: {
//       heatrider: {
//         finished: finished
//       }
//     },
//     success: function(data) {
//       console.log(data);
//       if (data) {
//         if (($('div[heatrider-id=' + heatrider_id + ']').length)) {
//           return $.each(data.heatriders, function(hr) {
//             var sel;
//             sel = data.heatriders[hr];
//             if (sel.id === parseInt(heatrider_id)) {
//               return post_update_tablet(sel, $button);
//             }
//           });
//         } else {
//           return post_update_list_view(data);
//         }
//       } else {
//         return alert("something went wrong: no data! => put heatrider");
//       }
//     },
//     error: function(data) {
//       return alert("something went wrong: put heatrider");
//     }
//   });
// };
//
// // ---
// // generated by coffee-script 1.9.2
